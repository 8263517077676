/* eslint-disable react/no-unknown-property */
import React from 'react'
import '../styles/institucional.scss'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout/index'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import iconPDF from '../../images/icons/001-pdf-file 1.svg'
import PDF from '../../content/Grupo-Dia_Código-Ético_BR_REV_1.pdf'
import Brasil from '../../images/institucional/Brasil.svg'

import dots from '../../images/institucional/dots.svg'
import cliente from '../../images/institucional/pin-cliente.svg'
import resultados from '../../images/institucional/pin-resultados.svg'
import colaboracao from '../../images/institucional/pin-colaboracao.svg'
import crescimento from '../../images/institucional/pin-crescimento.svg'
import simplicidade from '../../images/institucional/pin-simplicidade.svg'
import pinTracejado from '../../images/institucional/pin tracejado.svg'
import pinCheio from '../../images/institucional/pin cheio.svg'
import pinContorno from '../../images/institucional/pin contorno.svg'
import tracejado from '../../images/institucional/tracejado.svg'
import balao from '../../images/institucional/balao.svg'
import medalha from '../../images/institucional/medalha.svg'
import phone from '../../images/institucional/phone.svg'
import espanha from '../../images/institucional/espanha.svg'
import portugal from '../../images/institucional/portugal.svg'
import argentina from '../../images/institucional/argentina.svg'
import brasil from '../../images/institucional/brasil.svg'
import cursor from '../../images/institucional/cursor.svg'

import dotsMobile from '../../images/institucional/dots-mobile.svg'
import tracejadoMobile from '../../images/institucional/tracejado-mobile.svg'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-lg-block d-none">
      <div className="backgroud-banner-diaxpress w-100 d-flex align-items-center">
        <div className="container py-5">
          <div className="row h-100 mt-4">
            <div className="col-6 ml-5">
              <h2 className="ml-5 text-red font-size-18">Assim fazemos as <br />coisas no Grupo DIA.</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-lg-none d-block">
      <div className="backgroud-banner w-100">
        <div className="container">
          <div className="row codigoEtica">
            <h3 className="text-red">Assim fazemos as  coisas <br />no Grupo DIA.</h3>
          </div>
        </div>
      </div>
    </section>
  </>
)

const CodigoEtica = ({ etica }) => (
  <section className="container mb-5">
    <div className="row">
      <div className="col-lg-3 mt-5 d-none d-lg-flex justify-content-end">
        <GatsbyImage image={etica} className='shadow radius w-80' alt="Um card com uma imagem de verduras no mercado" />
      </div>
      <div className="col-lg-9 px-lg-3 px-4">
        <h1 tabIndex={0} className="mb-3 mt-5 text-red" >Código de Ética</h1>
        <p className="mb-3 font-size-1">No Grupo DIA estamos convencidos de que nossa cultura ética é a base para cumprir com nossos valores e nossos objetivos. O Código Ético é o elemento essencial que guia cada dia nossa atividade entorno da confiança.</p>
        <p className='font-size-1'>Colocamos à disposição de todas as pessoas, empresas, organizações e instituições o nosso Código de Ética para que conheçam melhor nosso marco de atuação.</p>
        <div className="d-flex d-lg-none justify-content-center">
          <GatsbyImage image={etica} className='shadow radius mt-3 mb-3 w-75' alt="Um card com uma imagem de verduras no mercado" />
        </div>
        <a className="d-none d-lg-flex mt-4" href={PDF} target="_blank" rel="noopener noreferrer">
          <button className="btn btn-red2 pr-4 pl-4 pt-2 pb-2 text-white d-flex justify-content-center font-size-13 line-height align-items-lg-center align-items-start">
            <img src={iconPDF} alt="Icone de pdf" className="pr-3" />
            <span className='font-size-1'>Para conhecer nosso Código Ética na íntegra, clique aqui</span>
          </button>
        </a>
        <a className="d-flex d-lg-none mt-3" href={PDF} target="_blank" rel="noopener noreferrer">
          <button className="btn btn-red2 pr-4 pl-4 pt-2 pb-2 w-100 text-white d-flex justify-content-center font-size-13 line-height align-items-lg-center align-items-start">
            <img src={iconPDF} alt="Icone de pdf" className="pr-3" />
            <span className='font-size-1'>Para conhecer nosso Código Ética <br /> na íntegra, clique aqui</span>
          </button>
        </a>
      </div>
    </div>
  </section>
)

const ValoresDia = () => (
  <section className="container">
    <div className="row">
      <div className='col-lg-4 bg-dia-red my-lg-5 py-lg-0 py-5 text-white codigo-radius d-flex justify-content-center align-items-center position-relative'>
        <img src={pinCheio} className='position-absolute pinCheio' />
        <img src={pinContorno} className='position-absolute pinContorno' />
        <div className='px-lg-5 px-3 py-lg-0 py-5'>
          <h1>Valores Dia</h1>
          <p>Os valores do Dia são a referência de sempre agir corretamente e tomar decisões a partir de uma Cultura Ética.</p>
        </div>
        <img src={pinTracejado} className='position-absolute pinTracejado' />
      </div>
      <div className='col-lg-8 bg-map position-relative'>
        <img src={dots} alt="Imagem" className='d-lg-block d-none my-5 py-5 dots' />
        <img src={dotsMobile} alt="Imagem" className='d-lg-none d-block my-5 py-5 dots' />
        <div className='d-lg-flex d-none'>
          <div className='row position-absolute road'>
            <div className='col-lg-6 col-10 p-0'>
              <img src={cliente} alt="Cliente" className='icon1 mt-4 ml-5' />
              <div className='road-text ml-5 mr-2 mt-2'>
                <p className='font-weight-bold'>Paixão pelo <span className='text-purple'>Cliente</span></p>
                <p>Estamos próximos, oferecendo produtos de grande qualidade a preços acessíveis, além de uma experiência de compra prática e rápida para conquistar o nosso cliente a cada dia.</p>
              </div>
            </div>
            <div className='col-lg-5 col-10 p-0'>
              <img src={resultados} alt="Resultados" className='icon1 mt-4 ml-lg-2 ml-5' />
              <div className='road-text ml-lg-2 ml-5 mr-3 mt-2'>
                <p className='font-weight-bold'>Compromisso com os <span className='text-orange'>Resultados</span></p>
                <p>Geramos impacto a partir de objetivos claros, ambiciosos, realistas e celebramos conquistas quando as atingimos.</p>
              </div>
            </div>
            <div className='col-lg-6 col-10 p-0'>
              <img src={colaboracao} alt="Colaboração" className='icon1 mt-4 ml-5' />
              <div className='road-text ml-5 mr-2 mt-2'>
                <p className='font-weight-bold'>Espírito de <span className='text-green'>Colaboração</span></p>
                <p>Fomentamos o trabalho em equipe e construímos vínculos autênticos e de conﬁança baseados na nossa essência: a proximidade.</p>
              </div>
            </div>
            <div className='col-lg-5 col-10 p-0'>
              <img src={crescimento} alt="Resultados" className='icon1 mt-4 ml-lg-2 ml-5' />
              <div className='road-text ml-lg-2 ml-5 mr-3 mt-2'>
                <p className='font-weight-bold'><span className='text-yellow'>Crescimento</span> contínuo</p>
                <p>Geramos impacto a partir de objetivos claros, ambiciosos, realistas e celebramos conquistas quando as atingimos.</p>
              </div>
            </div>
            <div className='col-lg-4'></div>
            <div className='col-lg-6 col-10 p-0'>
              <img src={simplicidade} alt="Colaboração" className='icon1 mt-lg-4 mt-2 ml-5' />
              <div className='road-text ml-5 mr-2 mt-2'>
                <p className='font-weight-bold'><span className='text-blue'>Simplicidade</span> no que fazemos</p>
                <p>Aportamos valor, somos eﬁcientes e geramos alto impacto. Ter um propósito claro nos permite ser coerentes e estar onde, quando e como temos que estar.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='d-lg-none d-flex'>
          <div className='row'>
            <div className='position-absolute road'>
              <div className='col-lg-6 col-10 p-0'>
                <img src={cliente} alt="Cliente" className='icon1 mt-4 ml-5' />
                <div className='road-text ml-5 mr-2 mt-2'>
                  <p className='font-weight-bold'>Paixão pelo <span className='text-purple'>Cliente</span></p>
                  <p>Estamos próximos, oferecendo produtos de grande qualidade a preços acessíveis, além de uma experiência de compra prática e rápida para conquistar o nosso cliente a cada dia.</p>
                </div>
              </div>
              <div className='col-lg-5 col-10 p-0'>
                <img src={resultados} alt="Resultados" className='icon1 mt-4 ml-lg-2 ml-5' />
                <div className='road-text ml-lg-2 ml-5 mr-3 mt-2'>
                  <p className='font-weight-bold'>Compromisso com os <span className='text-orange'>Resultados</span></p>
                  <p>Geramos impacto a partir de objetivos claros, ambiciosos, realistas e celebramos conquistas quando as atingimos.</p>
                </div>
              </div>
              <div className='col-lg-6 col-10 p-0'>
                <img src={colaboracao} alt="Colaboração" className='icon1 mt-4 ml-5' />
                <div className='road-text ml-5 mr-2 mt-2'>
                  <p className='font-weight-bold'>Espírito de <span className='text-green'>Colaboração</span></p>
                  <p>Fomentamos o trabalho em equipe e construímos vínculos autênticos e de conﬁança baseados na nossa essência: a proximidade.</p>
                </div>
              </div>
              <div className='col-lg-5 col-10 p-0'>
                <img src={crescimento} alt="Resultados" className='icon1 mt-4 ml-lg-2 ml-5' />
                <div className='road-text ml-lg-2 ml-5 mr-3 mt-2'>
                  <p className='font-weight-bold'><span className='text-yellow'>Crescimento</span> contínuo</p>
                  <p>Geramos impacto a partir de objetivos claros, ambiciosos, realistas e celebramos conquistas quando as atingimos.</p>
                </div>
              </div>
              <div className='col-lg-4'></div>
              <div className='col-lg-6 col-10 p-0'>
                <img src={simplicidade} alt="Colaboração" className='icon1 mt-lg-4 mt-2 ml-5' />
                <div className='road-text ml-5 mr-2 mt-2'>
                  <p className='font-weight-bold'><span className='text-blue'>Simplicidade</span> no que fazemos</p>
                  <p>Aportamos valor, somos eﬁcientes e geramos alto impacto. Ter um propósito claro nos permite ser coerentes e estar onde, quando e como temos que estar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const PrincipiosEticos = ({ people }) => (
  <section className="container mt-5 principiosEticos">
    <div className="row">
      <div className='col-lg-5 p-0 bg-dia-red my-lg-5 pb-5 text-white codigo-radius position-relative'>
        <img src={medalha} className='position-absolute z-index-1' style={{ right: 4 + 'em', top: 9.5 + 'em' }} />
        <div className='pl-lg-5'>
          <GatsbyImage image={people} className='w-100' alt='Pessoas' />
        </div>
        <div className='d-flex justify-content-center align-itens-center'>
          <div className='px-lg-5 px-4 mt-5'>
            <h1>Princípios Éticos</h1>
            <p>As pessoas do Grupo Dia desenvolvem suas obrigações proﬁssionais de forma coerente com os Valores do Dia e com os cinco princípios éticos que são desenvolvidos abaixo.</p>
            <div className='position-relative mt-5'>
              <img src={balao} />
              <p className='position-absolute ml-4 mt-3' style={{ top: 0, lineHeight: 1 }}><b>Somos uma</b><br></br>empresa ética</p>
            </div>
            <p>Temos a ﬁrme vontade de continuar crescendo e promovendo uma <b>Cultura Ética</b> através de um modelo de ética e integridade.</p>
          </div>
        </div>
      </div>
      <div className='col-lg-7 codigo-bg-gray pb-lg-0 pb-5 position-relative'>
        <img src={tracejado} className='d-md-block d-none mt-5 ml-5 pt-4 position-absolute' />
        <img src={tracejadoMobile} className='d-md-none d-block mt-5 ml-5 pt-4 position-absolute tracejadoMobile' />
        <div className='row pb-lg-0 pb-5 road'>
          <div className='col-1 mt-5 ml-4'>
            <div className='bg-green'></div>
          </div>
          <div className='col-md-10 col-8 ml-lg-3 ml-md-4 ml-5 mt-5 pt-2'>
            <h3 className='text-green m-0'>RESPEITO</h3>
            <p>Respeitamos as pessoas e geramos um <b>espírito de colaboração</b> e um ambiente de conﬁança, diverso e inclusivo, no qual defendemos diferentes opiniões.</p>
          </div>
          <div className='col-1 mt-4 ml-4'>
            <div className='bg-orange'></div>
          </div>
          <div className='col-md-10 col-8 ml-lg-3 ml-md-4 ml-5 mt-4 pt-2'>
            <h3 className='text-orange m-0'>INTEGRIDADE</h3>
            <p>Atuamos com integridade e transparência. Procuramos fazer a coisa certa e alcançar os melhores <b>resultados</b> sem prejudicar os interesses dos outros.</p>
          </div>
          <div className='col-1 mt-4 ml-4'>
            <div className='bg-blue'></div>
          </div>
          <div className='col-md-10 col-8 ml-lg-3 ml-md-4 ml-5 mt-4 pt-2'>
            <h3 className='text-blue m-0'>COMPROMISSO</h3>
            <p>Nossa <b>paixão</b> e compromisso é que o <b>cliente</b> e as demais partes interessadas do Grupo Dia são uma parte essencial de nossa empresa.</p>
          </div>
          <div className='col-1 mt-4 ml-4'>
            <div className='bg-yellow'></div>
          </div>
          <div className='col-md-10 col-8 ml-lg-3 ml-md-4 ml-5 mt-4 pt-2'>
            <h3 className='text-yellow m-0'>LEALDADE</h3>
            <p>Trabalhamos de forma leal, cumprindo todos os regulamentos e compromissos do Grupo Dia. Procuramos o <b>crescimento contínuo</b> e aprendemos todos os dias as implicações da nossa atividade e a forma como a mesma é regulada.</p>
          </div>
          <div className='col-1 mt-4 ml-4'>
            <div className='bg-light-pink'></div>
          </div>
          <div className='col-md-10 col-8 ml-lg-3 ml-md-4 ml-5 mt-4 pt-2'>
            <h3 className='text-light-pink m-0'>SIMPLICIDADE</h3>
            <p>Em nosso trabalho diário priorizamos a <b>simplicidade</b> no que fazemos. Nosso foco é cuidar dos ativos e das informações que a Empresa nos disponibiliza.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const CanalDenuncias = ({ canal }) => (
  <section className="container contato my-5">
    <GatsbyImage image={canal} className='w-100' alt="Imagem com as informações do Código de Ética" />
    {/* <div className="row">
      <div className="col-12 codigo-bg-gray p-5">
        <h1 className='text-red'>Como posso entrar em contato com o comitê de ética?</h1>
        <p>A empresa possui um Canal de Informação Interno de consulta e comunicação, denominado LinhaÉtica, cujo objetivo é permitir que todos os terceiros interessados resolvam dúvidas e comuniquem possíveis violações do Código ou outras condutas irregulares. Este canal garante a privacidade e proteção do informante e é gerenciado pela Diretoria de Compliance e pelos diversos Comitês de Ética. Para fazer isso, você pode acessar com segurança os seguintes links, dependendo do país onde você está localizado:</p>
        <div className='d-lg-flex d-none justify-content-between px-4 mt-4'>
          <div className='d-flex align-itens-center'>
            <img src={phone} className='mr-1' />
            <p>900 833 826 (4543)</p>
          </div>
          <div className='d-flex align-itens-center'>
            <img src={phone} className='mr-1' />
            <p>800 831 524 (4543)</p>
          </div>
          <div className='d-flex align-itens-center'>
            <img src={phone} className='mr-1' />
            <p>0800 444 29 15 (4543)</p>
          </div>
          <div className='d-flex align-itens-center'>
            <img src={phone} className='mr-1' />
            <p>0800 591 20 78 (4543)</p>
          </div>
        </div>
        <div className='d-lg-flex d-none mt-2 mb-4'>
          <a href="tel:900 833 826">
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative ml-1 mr-5'>
              <img src={espanha} className='position-absolute' style={{ left: 0 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
          </a>
          <a href="tel:800 831 524">
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative ml-2 mr-5'>
              <img src={portugal} className='position-absolute' style={{ left: 0 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
          </a>
          <a href="tel:0800 444 29 15">
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative ml-3 mr-5'>
              <img src={argentina} className='position-absolute' style={{ left: -6 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
          </a>
          <a href="https://report.whistleb.com/pt/dialineaetica">
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative ml-4'>
              <img src={brasil} className='position-absolute' style={{ left: -6 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
          </a>
        </div>

        <div className='d-lg-none d-flex justify-content-center my-5'>
          <div>
            <div className='d-flex align-itens-center justify-content-center'>
              <img src={phone} className='mr-1' />
              <p>900 833 826 (4543)</p>
            </div>
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative mt-2'>
              <img src={espanha} className='position-absolute' style={{ left: 0 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
            <div className='d-flex align-itens-center justify-content-center mt-4'>
              <img src={phone} className='mr-1' />
              <p>800 831 524 (4543)</p>
            </div>
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative mt-2'>
              <img src={portugal} className='position-absolute' style={{ left: 0 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
            <div className='d-flex align-itens-center justify-content-center mt-4'>
              <img src={phone} className='mr-1' />
              <p>0800 444 29 15 (4543)</p>
            </div>
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative mt-2'>
              <img src={argentina} className='position-absolute' style={{ left: -6 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
            <div className='d-flex align-itens-center justify-content-center mt-4'>
              <img src={phone} className='mr-1' />
              <p>0800 591 20 78 (4543)</p>
            </div>
            <div className='d-flex bg-dark-gray align-items-center justify-content-center position-relative mt-2'>
              <img src={brasil} className='position-absolute' style={{ left: -6 }} />
              <p className='text-white ml-4'>clique aqui</p>
              <img src={cursor} className='position-absolute' style={{ right: 0, bottom: -17 + 'px' }} />
            </div>
          </div>
        </div>
        <p>Além disso, se você receber qualquer comunicação por correio ou qualquer outro meio, que possa estar relacionado na comunicação de comportamento suspeito, você deve enviá-la através da LinhaÉtica ou entrar em contato com o comitê de ética ou o departamento de compliance correspondente.</p>
        <p><b>O processamento da sua comunicação será totalmente conﬁdencial</b> e poderá anexar na comunicação toda a documentação e detalhes que julgar pertinente.<br></br><b>O Grupo Dia</b> não tolerará qualquer retaliação a qualquer pessoa que tenha feito uso da LinhaÉtica de boa fé.</p>
      </div>
    </div> */}
  </section>
)

const Institucional = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      // { path: '/institucional/', name: 'Institucional' },
      { path: '/institucional/codigo-de-etica/', name: 'Código de Ética' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Código de Ética - Dia Supermercados</title>
        <meta name="description" content="No Grupo DIA estamos convencidos de que nossa cultura ética é a base para cumprir com nossos valores e nossos objetivos. Conheça o nosso Código de Ética." />
        <meta name="keywords" content="codigo de etica, codigo de etica dia supermercado" />
        <meta name="author" content="DIA Supermercados" />
        <meta
          property="og:image"
          content='https://www.dia.com.br/card-dia.png'
        />
      </Helmet>
      <Banner />

      <CodigoEtica etica={data.codigoetica.childImageSharp.gatsbyImageData} />
      <ValoresDia />
      <PrincipiosEticos people={data.people.childImageSharp.gatsbyImageData} />
      <CanalDenuncias canal={data.canal.childImageSharp.gatsbyImageData} />
    </Layout>
  )
}
export default Institucional

export const query = graphql`{
  atualmente: file(relativePath: {eq: "institucional/2020.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 230, height: 130, layout: CONSTRAINED)
    }
  }
  codigoetica: file(relativePath: {eq: "institucional/capa.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  people: file(relativePath: {eq: "institucional/people.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  canal: file(relativePath: {eq: "institucional/canal_atualizado.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
